<!-- eslint-disable -->
<template>
  <base-material-card
      icon="mdi-clipboard-text"
      title="Voir la liste des colis"
      class="px-5 py-3"
    >
    <template v-slot:heading>
      <v-icon @click="reloadItems">mdi-reload</v-icon>
    </template>
    <!-- <v-row>
    <v-col cols="12" sm="2">
      <base-material-stats-card
            color="primary"
            icon="mdi-package-variant-closed"
            title="Livrée"
            :value="delivered.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="info"
          icon="mdi-truck-delivery"
          title="EnRoute"
          :value="engoing.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="orange"
          icon="mdi-motion-pause-outline"
          title="Chargement"
          :value="enhold.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="gray"
          icon="mdi-briefcase-check-outline"
          title="Created"
          :value="created.toString()"
        />
    </v-col>
    <v-col cols="12" sm="2">
      <base-material-stats-card
          color="red"
          icon="mdi-message-alert"
          title="Returned"
          :value="returned.toString()"
        />
    </v-col>
    </v-row> -->
    <b-modal v-model="showColisModal"  title='CREATION DE COLIS' hide-footer size="xl">
    <b-form @submit.prevent="createDelivery">
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="provider"
                    disabled
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field required
                    class="purple-input"
                    label="Nom de l'expéditeur"
                    v-model="clientname"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de l'expéditeur"
                    class="purple-input"
                    v-model="phone"
                  />
                </v-col>
                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    :label="provider"
                    disabled
                  />
                </v-col>

                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field required
                    class="purple-input"
                    label="Nom de receveur"
                    v-model="receivername"
                  />
                </v-col>
                <v-col
                style="margin-top:-30px"
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="N° Téléphone de receveur"
                    class="purple-input"
                    v-model="receiverphone"
                  />
                </v-col>
                <v-row v-if="checked==true">
                  <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot de départ</label>
                  <b-form-select v-model="selectedDepot1" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya de départ</label>
                  <b-form-select v-model="selectedWilaya1"  required>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue de départ</label>
                  <b-form-select v-model="selectedCommunue1" required>
                    <option v-for="wilaya in selectedWilaya1.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Adresse de départ"
                    class="purple-input"
                    v-model="addr1"
                  />
                </v-col>
                </v-row>
                <v-row v-else>
                  <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot de départ</label>
                  <b-form-select v-model="selectedDepot1" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya de départ</label>
                  <b-form-select v-model="selectedWilaya1" disabled>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue de départ</label>
                  <b-form-select v-model="selectedCommunue1" disabled>
                    <option v-for="wilaya in selectedWilaya1.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Adresse de départ"
                    class="purple-input"
                    v-model="addr1"
                    disabled
                  />
                </v-col>
                </v-row>
                <v-row v-if="checked2==true">
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot d'arrivée</label>
                  <b-form-select v-model="selectedDepot2" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya d'arrivée</label>
                  <b-form-select v-model="selectedWilaya2" required>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue d'arrivée</label>
                  <b-form-select v-model="selectedCommunue2" required>
                    <option v-for="wilaya in selectedWilaya2.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Adresse d'arrivée"
                    class="purple-input"
                    v-model="addr2"
                  />
                </v-col>
                </v-row>
                <v-row v-else>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Dépot d'arrivée</label>
                  <b-form-select v-model="selectedDepot2" @change="getFees" required>
                    <option v-for="wilaya in willayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <label>Wilaya d'arrivée</label>
                  <b-form-select v-model="selectedWilaya2" disabled>
                    <option v-for="wilaya in allwilayas" :key="wilaya.name" :value="wilaya">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <label>Communue d'arrivée</label>
                  <b-form-select v-model="selectedCommunue2" disabled>
                    <option v-for="wilaya in selectedWilaya2.communues" :key="wilaya.name" :value="wilaya.name">{{wilaya.name}}</option>
                  </b-form-select>

                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    disabled
                    label="Adresse d'arrivée"
                    class="purple-input"
                    v-model="addr2"
                  />
                </v-col>
                </v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    required
                    label="Marchandises"
                    class="purple-input"
                    v-model="produit"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    required
                    label="Poids (Par Kg)"
                    class="purple-input"
                    v-model="weight"
                    @input="changeFees"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-text-field
                    disabled
                    label="N° de colis"
                    class="purple-input"
                    v-model="quantity"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"                
                >
                <b-form-checkbox class="myDIV" v-model="checked" name="check-button" switch @change="getFees" v-b-tooltip.hover title="Le chauffeur va ramasser le colis d'après l'adresse de départ que vous entrer">Ramasser <b>(Checked: {{ checked }})</b></b-form-checkbox>
                <b-form-checkbox class="myDIV" v-model="checked2" name="check-button" switch @change="getFees" v-b-tooltip.hover title="Le chauffeur va livrer le colis à l'adresse de d'arrivé que vous entrer">Domicile <b>(Checked: {{ checked2 }})</b></b-form-checkbox>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <label>Type de paiement</label>
                  <b-form-select v-model="payment_method" @change="getFees" required>
                    <option v-for="payment_method in ['Acheteur couvre tous les frais','Acheteur couvre juste les frais de colis','Acheteur couvre juste les frais de livraison','Vendeur couvre tous les frais']" :key="payment_method" :value="payment_method">{{payment_method}}</option>
                  </b-form-select>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-text-field
                    required
                    label="Prix"
                    class="purple-input"
                    v-model="price"
                    @input="changeFees"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    disabled
                    label="Prix de livraison"
                    class="purple-input"
                    v-model="deliveryPrice"
                    type="number"
                    step="any"
                  />
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    class="purple-input"
                    label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                    v-model="text"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <p  v-if="responseMessage != null" style="color:red;">{{responseMessage}}</p>
                  <p  v-if="totalPrice!=null" style="color:green;">Prix total : {{totalPrice}}</p>
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <p  v-if="tracking_id!=null" style="color:green;">*Votre colis a été crée avec success*<br> Votre Tracking ID est  : {{tracking_id}}</p>
                </v-col>


                <v-col
                  cols="12"
                  md="4"
                  class="text-right"
                >
                  <v-btn
                    type='submit'
                    color="success"
                    style="background-color:green"
                    class="mr-0"
                  >
                    Creer une livraison
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </b-form>
    </b-modal>
    <b-modal v-model="show2" @ok="getpaid()" title='CONFIRMATION DE PAIEMENT'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer le paiement</p>

    </b-modal>
    <b-modal v-model="show3" @ok="deletePackage" title='CONFIRMATION DE SUPPRESSION'>
      <!-- <h3 style="color:green">CONFIRMATION DE PAIEMENT</h3> -->
      <br><v-icon style="color:red">mdi-alert</v-icon>Une fois cliqué OK, vous ne pouvez pas faire l'opération inverse<br><br>
      <p style="color:green"><v-icon style="color:green">mdi-check-box-multiple-outline</v-icon> OK pour confirmer la suppression</p>
    </b-modal>
    <!-- <b-modal v-model="show">
      <h3>{{infoshow.tracking_id}} :{{infoshow.content}}</h3><p>Clients name : {{infoshow.client_name}}</p><p>Phone Number : {{infoshow.client_phone}}</p>
      <p v-for="histori in infoshow.history" :key="histori.message">***{{moment(histori.creation_date).format('MM/DD/YYYY HH:mm')}} : {{histori.message}}</p>
    </b-modal> -->
    <!-- <b-modal v-model="show4" @hidden="handleModalHide" hide-footer size="xl">
      <template #modal-title>
        Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
      </template>
        <div style="text-align:center;">
          <v-btn class="mr-3" style="color:white;background-color:#FBC02D" @click="makeChanges2('Confirmé')">Confirmé</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:#8E24AA" @click="makeChanges2('EnRoute')">EnRoute</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:#0096FF" @click="makeChanges2('Chargement')">Chargement</v-btn>
          <v-btn class="mr-3" style="color:white;background-color:green" @click="makeChanges2('Livrée')">Livrée</v-btn>
        </div>
      <hr>
    </b-modal> -->
    <b-modal v-model="show4" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
      <div style="text-align:center;">
        <v-btn style="color:white;background-color:#616161" @click="makeChanges2('Created')">Created</v-btn>
        <v-btn style="color:white;background-color:#FBC02D" @click="makeChanges2('Confirmé')">Confirmé</v-btn>
        <v-btn style="color:white;background-color:#0096FF" @click="makeChanges2('Chargement')">Chargement</v-btn>
        <v-btn style="color:white;background-color:#8E24AA" @click="makeChanges2('EnRoute')">EnRoute</v-btn>
        <v-btn style="color:white;background-color:#000080" @click="makeChanges2('Arrivé')">Arrivé</v-btn>
        <v-btn style="color:white;background-color:green"   @click="makeChanges2('Livrée')">Livrée</v-btn>
      </div>
    <hr>
    <b-form >
      <!-- @submit.prevent="makeChanges" -->
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_name" label="Nom de receveur" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field v-model="packageDetail.receiver_phone" label="N° Téléphone de receveur" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.from_wilaya"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address1"/>
              </v-col>
              <v-col cols="12" md="6">
                <b-form-select required v-model="packageDetail.to_wilaya"><option :value="wilaya.name" v-for="wilaya in willayas" :key="wilaya.name">{{wilaya.name}}</option> </b-form-select>
                <v-text-field required label="Adresse de départ" class="purple-input" v-model="packageDetail.address2"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required label="Marchandise" class="purple-input" v-model="packageDetail.content"/>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field required label="Poids" class="purple-input" v-model="packageDetail.weight"/>
              </v-col>
              <v-col cols="12" md="3">
                <v-text-field required label="Quantité" class="purple-input" v-model="packageDetail.quantity"/>
              </v-col>
            </v-row>
            <v-row>
                <b-form-select required v-model="packageDetail.selected_driver">
                  <option v-for="driver in drivers" :key="driver.did" :value="driver.did" :class="(driver.available===true || packageDetail.selected_driver===driver.did ? 'color-green' : (driver.available === false ? 'color-red': ''))">
                    <span v-if="driver.available==false">{{driver.name}} {{driver.phone}}</span>
                    <span v-else>{{driver.name}} {{driver.phone}}</span>
                  </option>
                </b-form-select>
            </v-row>
            <v-row>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.colis_price" label="Prix de livraison" type="number" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="4">
                <v-text-field required v-model="packageDetail.delivery_price" label="Frais de livraison" type="number" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-textarea
                required
                class="purple-input"
                label="Plus de détails (Max 1000 caractères, sinon laisser vide)"
                v-model="packageDetail.description"
              />
            </v-col>
        </div>
      </div>
    </b-form>
  </b-modal>
    <b-modal v-model="show" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <div class="d-block text-center" style="background-color:white">
      <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
        <h4>Section des dates</h4>
        <strong>Date de création: </strong>{{serialiseDate(packageDetail.creation_date)}}<br>
        <strong>Date d'expédition: </strong>{{serialiseDate(packageDetail.shipping_date)}}<br>
        <strong>Date de livraison: </strong>{{serialiseDate(packageDetail.delivery_date)}}<br>
        <hr>
          <h4>Section d'historique</h4>
          <p v-for="histori in infoshow.history" :key="histori.message"><strong>{{moment(histori.creation_date).format('DD/MM/YYYY HH:mm')}}: </strong> {{histori.message}}</p>
        <hr>
        <h4>Section d'infomation de client</h4>
        <strong>Nom complet de client: </strong>{{packageDetail.client_name}}<br>
        <strong>N° Téléphone de client: </strong>{{packageDetail.client_phone}}<br>
        <strong>Nom complet de receveur: </strong>{{packageDetail.receiver_name}}<br>
        <strong>N° Téléphone de receveur: </strong>{{packageDetail.receiver_phone}}<br>
        <strong>Contenu de colis: </strong>{{packageDetail.content}}<br>
        <strong>Prix de colis: </strong>{{packageDetail.colis_price}}<br>
        <strong>Prix de livraison: </strong>{{parseInt(packageDetail.delivery_price)}}<br>
        <strong>Prix Total: </strong>{{parseInt(packageDetail.total_price)}}<br>
      </div>
    </div>
    <b-button class="mt-3" style="color:white" block @click="hideModal">Fermer</b-button>
  </b-modal>
  <b-modal v-model="showChangable" @hidden="handleModalHide" hide-footer size="xl">
    <template #modal-title>
      Détail de Colis <b-badge variant="success">{{packageDetail.tracking_id}}</b-badge>
    </template>
    <b-form @submit.prevent="makeChanges">
      <div class="d-block text-center" style="background-color:white">
        <div class="form-group" v-if="packageDetail != null && packageDetail != undefined">
            <v-row>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_name" label="Nom de client" class="purple-input"/>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field required v-model="packageDetail.client_phone" label="N° Téléphone" class="purple-input"/>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="12">
                <v-text-field required v-model="packageDetail.price" label="Prix de colis" type="number" class="purple-input"/>
              </v-col>
            </v-row>
        </div>
      </div>
      <b-button class="mr-3 float-right" style="color:white;" @click="hideModal">Annuler</b-button>
      <b-button class="mr-3 float-right" style="color:white;" variant="success" type="submit">Confirmer</b-button>
    </b-form>
  </b-modal>
    <!-- <v-simple-table>
      <thead>
          <tr>
            <th class="primary--text">
              <strong>ID</strong>
            </th>
            <th class="primary--text">
              Produit
            </th>
            <th class="primary--text">
              Client
            </th>
            <th class="primary--text">
              Status
            </th>
            <th class="primary--text">
              Post
            </th>
            <th class="text-right primary--text">
              Prix
            </th>

          </tr>
        </thead>
        <tbody>
          <tr v-for="pakage in packages" :key="pakage.package_id">
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')"><v-btn depressed small color="#37474F">{{pakage.package_id}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.content}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.client_name}}</td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Livrée'"><v-btn depressed small color="green">{{pakage.status}}</v-btn></td>
            <td v-if="((pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')) && pakage.status == 'Created'"><v-btn depressed small color="blue">{{pakage.status}}</v-btn></td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' && wilaya_search == 'All')">{{pakage.wilaya}}</td>
            <td v-if="(pakage.status == status_search) && (pakage.wilaya == wilaya_search) || (status_search == 'All' &&  wilaya_search == 'All')" class="text-right">
              {{pakage.price}}
            </td>
          </tr>

        </tbody>
    </v-simple-table> -->
    <div style="width:100%;text-align:right">
     <v-btn color="success" small depressed @click="showColisModal = true">Ajouter un colis</v-btn>
    </div>
    <vue-good-table
      :columns="columns"
      :rows="rows"
      :isLoading.sync="isLoading"
      @on-column-filter="filtering"
      :pagination-options="{enabled: true, perPageDropdown:[10, 20,50,100,200],perPage:15}"
      @on-row-click="RowClick">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'status'">
          <v-btn id="status-btn" v-if="props.row.status == 'Created'" depressed small color="#616161">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Confirmé'" depressed small color="#FBC02D">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Chargement'" depressed small color="#0096FF">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'EnRoute'" depressed small color="#8E24AA">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Arrivé'" depressed small color="#000080">{{props.row.status}}</v-btn>
          <v-btn id="status-btn" v-if="props.row.status == 'Livrée'" depressed small color="green">{{props.row.status}}</v-btn>
          <!-- <v-btn v-if="props.row.status == 'Arrived'" depressed small color="blue">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ONGO'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-DLI'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn v-if="props.row.status == 'Returned-ARV'" depressed small color="#D50000">{{props.row.status}}</v-btn>
          <v-btn id="returned-btn" v-if="props.row.status == 'Returned'" depressed small color="#D50000">{{props.row.status}}</v-btn> -->
          <v-icon  v-if="props.row.status == 'Created'" id="history-btn" style="margin-left:10px;color:red">mdi-close-box</v-icon>
        </span>
        <span v-else-if="props.column.field == 'tracking_id'">
          <v-icon id='print-btn' style="margin-right:10px" color="primary">mdi-printer</v-icon>
          <v-btn id="tracking-id-btn" v-b-tooltip.hover :title="copyMessage" depressed small color="#37474F">{{props.row.tracking_id}}</v-btn>
          <v-icon v-b-tooltip.hover title="Livraison à domicile" v-if="props.row.delivery_type=='Home'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon>
          <v-icon v-b-tooltip.hover title="Livraison gratuit" v-if="props.row.free_delivered==true" style="margin-left:10px;margin-right:10px;" color="primary">mdi-currency-usd-off</v-icon>
          <!-- <v-icon v-if="props.row.delivery_type=='Stop Desk'" style="margin-left:10px;margin-right:10px;" color="primary">mdi-home-map-marker</v-icon> -->
          <v-btn id="pay-btn" v-if="props.row.status == 'Livrée' && props.row.posttoprovider == false && props.row.free_delivered == false && props.row.price != 0" class="float-right" depressed small color="red">NOT PAID</v-btn>
          <v-btn v-if="props.row.status == 'Livrée' && props.row.posttoprovider == true && props.row.free_delivered == false && props.row.price != 0" depressed small color="green" class="float-right">GET PAID</v-btn>
          <!-- <v-btn v-if="props.row. == 'Livrée' && props.row.posttoprovider == true" depressed small color="green">GET PAID</v-btn> -->
        </span>
        <span v-else-if="props.column.field == 'price'">
          <span>{{props.row.price}}</span>
          <v-icon v-if="props.row.changable == true" id="changable-btn" v-b-tooltip.hover title="Cliquer pour changer" style="margin-left:10px;margin-right:10px;" color="primary">mdi-cog</v-icon>
          <!-- <b-form-input v-else id="changable-input" type="number" v-model="props.row.price" @keydown.native="makeChanges"></b-form-input> -->
        </span>
        <span v-else>{{props.row[props.column.field]}}</span>
      </template>
    </vue-good-table>
  </base-material-card>

</template>

<script>
/* eslint-disable */
import axios from 'axios'
import moment from 'moment'
export default {
  name: 'Dashboard',
  data () {
    return {
      // searchTerm: '',
      showColisModal:false,
      moment: moment,
      copyMessage: 'Cliquer pour copier',
      provider: null,
      pemail: null,
      fuckoff: null,
      posts: [],
      welcoming: null,
      packages: [],
      status_list: ['Created', 'Livrée','All'],
      wilaya_list: ['Alger', 'Annaba', 'Bejaia','All','Mascara'],
      produit_list: ['Phone', 'Ibad', 'Laptop','All'],
      status_search: 'All',
      wilaya_search: 'All',
      produit_search: 'All',
      search: null,
      packageDetail: null,
      columns: [
        {
          label:'Tracking_Id',
          field:'tracking_id',
          filterOptions: {enabled:true,placeholder: 'Entrer le tracking',}
        },
        {
          label:'Marchandise',
          field:'content',
          filterOptions: {enabled:true,placeholder: 'Entrer Produit',}
        },
        {
          label:'Nom de l\'expéditeur',
          field:'client_name',
          filterOptions: {enabled:true,placeholder: 'Entrer le Client',}
        },
        {
          label:'Status',
          field:'status',
          filterOptions: {enabled:true,placeholder: 'Choisir le status',filterDropdownItems:['Created','Livrée','EnRoute','Arrived','Chargement','Returned','Returned-ARV','Returned-DLI','Returned-ONGO']}
        },
        {
          label:'Départ',
          field:'from_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Arrivé',
          field:'to_wilaya',
          filterOptions: {enabled:true,placeholder: 'Entrer la wilaya'}
        },
        {
          label:'Prix',
          width: '120px',
          field:'price'
        }
      ],
      rows: [],
      willayas: [],
      show: false,
      show4:false,
      showChangable: false,
      infoshow: [],
      show2 : false,
      show3: false,
      tr_id2: null,
      delivered: null,
      returned: null,
      created: null,
      engoing: null,
      enhold: null,
      setset: null,
      isLoading: false,
      drivers: [],
      vehicules: ['Fourgon 400 KG','Fourgon 1.5 TN', 'Camion 2.5 TN', 'Camion 3.5 TN', 'Camion 10 TN', 'Camion 20 TN'],
        addr1:null,
        price:0,
        quantity:0,
        totalPrice:0,
        payment_method:null,
        addr2:null,
        responseMessage: null,
        produit: null,
        provider: null,
        selectedMethod: null,
        selectedWilaya1: {},
        selectedWilaya2: {},
        selectedCommunue1: null,
        selectedCommunue2: null,
        selectedDepot1: {},
        selectedDepot2: {},
        selectedVehicule: null,
        wilaya: null,
        posts: [],
        wilaya2: {dlv_type:'both'},
        checked: false,
        checked2: false,
        status: false,
        receiverphone:null,
        receivername:null,
        clientname: null,
        prix: null,
        phone: null,
        willaya: null,
        address: null,
        county: null,
        tracking_id: null,
        fais: 0,
        date: null,
        text: null,
        setset: null,
        fuckoff: null,
        allwilayas: [],
        willayas: [],
        communue: null,
        communues: [],
        deliveryPrice: 0,
        homePrice: 0,
        weight:null,
    }

  },
  mounted() {
    axios.defaults.baseURL = process.env.VUE_APP_BASE_URL
    this.date = new Date().toDateString()
    this.gettingdepots()
    this.gettingwilayas()
    this.getPostCenters()
    this.detectingprovider()
    this.getingpackages()
    this.getStatusList()
    this.loadDrivers()
      // this.detectingprovider()
      if(this.fuckoff=='error'){
            this.$router.push({name : "Home"})
          }
  },
  methods: {
    gettingdepots(){
        axios.post('get-depots').then(response => {
            this.willayas = response.data.data
        })
      },
      gettingwilayas() {
        axios.post('get-wilayas').then(response => {
            this.allwilayas = response.data.data
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            this.$set(this.columns[4].filterOptions, 'filterDropdownItems', names);
            this.$set(this.columns[5].filterOptions, 'filterDropdownItems', names);
        })
      },
      changeCommunues() {
        console.log(this.willaya)
        this.communues=this.willaya.communues
      },
      createDelivery() {
        this.responseMessage = null
        let client_wilaya = null
        let delivery_type = null
        if (this.checked && this.checked2) {
          delivery_type = 'Ramasser/Domicile'
        } else if (this.checked) {
          delivery_type = 'Ramasser'
        } else if (this.checked2) {
          delivery_type = 'Domicile'
        } else {
          delivery_type = ''
        }
        if (this.willaya == null) client_wilaya = null
        else client_wilaya = this.willaya.name
        axios.post('create-colis-express',{
          depot1: this.selectedDepot1.email,
          depot2: this.selectedDepot2.email,
          auth_token: localStorage.getItem('auth_token'),
          produit: this.produit,
          quantity:this.quantity,
          payment_method:this.payment_method,
          weight:this.weight,
          wilaya1: this.selectedWilaya1.name,
          wilaya2: this.selectedWilaya2.name,
          communue1: this.selectedCommunue1,
          communue2: this.selectedCommunue2,
          addr1: this.addr1,
          addr2: this.addr2,
          text:this.text,
          clientname: this.clientname,
          phone: this.phone,
          receiverphone: this.receiverphone,
          receivername:this.receivername,
          delivery_price: this.deliveryPrice,
          price: this.price,
          total_price:this.totalPrice,
          delivery_type:delivery_type,
          home_price:this.homePrice
        }).then(response =>{
          if (response.data.status == 'error') {
            this.responseMessage = response.data.error_message
          } else {
            this.tracking_id = response.data.data.track_id
            this.setset = response.data.status
            this.produit = null
            this.wilaya1 = null
            this.wilaya2 = null
            this.checked = false
            this.status = false
            this.clientname = null
            this.prix = null
            this.phone = null
            this.willaya = null
            this.address = null
            this.county = null
            this.text = null
          }
        })
      },
      changeFees() {
        this.getFees()
      },
      getwilaya(name) {
        for (let i in this.allwilayas) {
          if (name == this.allwilayas[i].name) {
            return this.allwilayas[i]
          }
        }
      },
      // getFees() {
        // this.fais = 0
        // for (let i = 0; i < this.willayas.length; i++) {
        //   if (this.willayas[i].name == this.selectedDepot1.name) {
        //     this.addr1 = this.willayas[i].address
        //     this.selectedWilaya1 = this.getwilaya(this.willayas[i].wilaya)
        //     this.selectedCommunue1 = this.willayas[i].communue
        //     if (this.checked) {
        //       this.fais += this.willayas[i].collect_fees
        //     }
        //   }
        //   if (this.willayas[i].name == this.selectedDepot2.name) {
        //     this.addr2 = this.willayas[i].address
        //     this.selectedWilaya2 = this.getwilaya(this.willayas[i].wilaya)
        //     this.selectedCommunue2 = this.willayas[i].communue
        //     if (this.checked2) {
        //       this.fais += this.willayas[i].collect_fees
        //     }
        //   }
        // }
      //   this.deliveryPrice = (parseInt((this.weight - 0.01) / 10) + 1) * 400 + this.fais
      //   if (this.payment_method == 'Acheteur couvre tous les frais') this.totalPrice = parseInt(this.price) + parseInt(this.deliveryPrice)
      //   else if (this.payment_method == 'Acheteur couvre juste les frais de colis') this.totalPrice = parseInt(this.price)
      //   else if (this.payment_method == 'Acheteur couvre juste les frais de livraison') this.totalPrice = parseInt(this.deliveryPrice)
      //   else if (this.payment_method == 'Vendeur couvre tous les frais') this.totalPrice = 0
      //   this.quantity = parseInt((parseFloat(this.weight) - 0.01) / 10) + 1
      // },
      getFees() {
        this.fais = 0
        for (let i = 0; i < this.willayas.length; i++) {
          if (this.willayas[i].name == this.selectedDepot1.name) {
            this.addr1 = this.willayas[i].address
            this.selectedWilaya1 = this.getwilaya(this.willayas[i].wilaya)
            this.selectedCommunue1 = this.willayas[i].communue
            if (this.checked) {
              this.fais += this.willayas[i].collect_fees
            }
          }
          if (this.willayas[i].name == this.selectedDepot2.name) {
            this.addr2 = this.willayas[i].address
            this.selectedWilaya2 = this.getwilaya(this.willayas[i].wilaya)
            this.selectedCommunue2 = this.willayas[i].communue
            if (this.checked2) {
              this.fais += this.willayas[i].collect_fees
            }
          }
        }
        if (this.selectedDepot1!=null && this.selectedDepot2!=null) {
            axios.post('get-btd', {
            auth_token: localStorage.getItem('auth_token'),
            depot1: this.selectedDepot1.id,
            depot2: this.selectedDepot2.id,
          }).then(response => {
            if (response.data.status == 'success') {
              this.responseMessage = null
              if (this.checked2) {
                this.fais = response.data.btd.delivery
                this.homePrice = response.data.btd.delivery - response.data.btd.stop_desk
              } 
              else {
                this.fais = response.data.btd.stop_desk
                this.homePrice = 0
              }
            } else if (response.data.error_message != undefined) {
              this.responseMessage = response.data.error_message
              if (this.checked2) this.fais = 800
              else this.fais = 400
            }
            if (this.quantity == 0 || this.quantity == null) {
              this.quantity = 1
            }
            if (this.weight <= 5) {
              this.deliveryPrice = this.fais * this.quantity
            } else {
              this.deliveryPrice = (parseInt(this.weight - 5) * 50) * this.quantity + this.fais
            }
            if (this.payment_method == 'Acheteur couvre tous les frais') this.totalPrice = parseInt(this.price) + parseInt(this.deliveryPrice)
            else if (this.payment_method == 'Acheteur couvre juste les frais de colis') this.totalPrice = parseInt(this.price)
            else if (this.payment_method == 'Acheteur couvre juste les frais de livraison') this.totalPrice = parseInt(this.deliveryPrice)
            else if (this.payment_method == 'Vendeur couvre tous les frais') this.totalPrice = 0
          })
        } else {
          this.fais = null
          this.responseMessage = null
        }
      },
    loadDrivers() {
      axios.post('admin-drivers', {
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        if (response.data.status == 'success') {
          this.drivers = [];
          for (let i = 0; i < response.data.data.length; i++) {
            if (response.data.data[i].approved == true) {
              this.drivers.push(response.data.data[i])
              // if (response.data.data[i].service_type == "Service Logistique" || response.data.data[i].service_type == "Logistique/Express") 
              // if (response.data.data[i].service_type == "Service Express" || response.data.data[i].service_type == "Logistique/Express") this.drivers2.push(response.data.data[i])
            }
          }          
        }
      })
    },
    makeChanges2(status) {
      axios.post('change-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        client_name:this.packageDetail.client_name,
        client_phone:this.packageDetail.client_phone,
        receiver_name:this.packageDetail.receiver_name,
        selected_driver:this.packageDetail.selected_driver,
        receiver_phone:this.packageDetail.receiver_phone,
        content:this.packageDetail.content,
        weight:this.packageDetail.weight,
        delivery_type:this.packageDetail.delivery_type,
        description:this.packageDetail.description,
        delivery_price:this.packageDetail.delivery_price,
        colis_price:this.packageDetail.colis_price,
        payment_method:this.packageDetail.payment_method,
        from_wilaya:this.packageDetail.from_wilaya,
        to_wilaya:this.packageDetail.to_wilaya,
        address1:this.packageDetail.address1,
        address2:this.packageDetail.address2,
        status:status,
      }).then(response => {
        if (response.data.status == 'success')
          this.getingpackages()
          this.show4 = false
      })
    },
    reloadItems() {
      this.getingpackages()
      this.isLoading = true
    },
    getPostCenters() {
        axios.post('get-post-centers').then(response => {
          if (response.data.status == 'success') {
            let names= []
            response.data.data.forEach(element=> {
              names.push(element.name)
            })
            
          }
        })
      },
    getpaid(){
      axios.post('getpaid',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.tr_id2,
        setset: this.setset,
      }).then(response => {
        this.getingpackages()
      })

    },
    copyTrackingId(row) {
      this.$clipboard(row.tracking_id);
      this.copyMessage = 'Copié!'
      setTimeout(() => {
        this.copyMessage = 'Cliquer pour copier'
      }, 2000);
    },
    deletePackage() {
      axios.post('deletecolis',{
          auth_token: localStorage.getItem('auth_token'),
          tr_id: this.packageDetail.tracking_id,
        }).then(response => {
          this.getingpackages()
        })
    },
    makeChanges() {
      axios.post('provider-change-package',{
        auth_token: localStorage.getItem('auth_token'),
        tr_id: this.packageDetail.tracking_id,
        new_price: this.packageDetail.price,
        client_name: this.packageDetail.client_name,
        client_phone: this.packageDetail.client_phone
      }).then(response => {
        if (response.data.status == 'success')
          this.getingpackages()
          this.showChangable = false
      })
    },
    RowClick(params){
      this.packageDetail = params.row
      if (params.event.target.id == "history-btn"){
        this.show3 = true
      }
      else if (params.event.target.id == "status-btn" || params.event.target.parentNode.id == 'status-btn'){
        this.show4 = true
      }
      else if (params.event.target.id == 'print-btn' || params.event.target.parentNode.id == 'print-btn') {
        axios.post('print-pdf-express', {
          auth_token: localStorage.getItem('auth_token'),
          tracking_id: this.packageDetail.tracking_id
        }).then(response=> {
          if (response.data.status == 'success') {
            const link = document.createElement('a')
            link.target = '_blank'
            link.href = process.env.VUE_APP_MEDIA_URL+"document.pdf"
            link.setAttribute('download', "document.pdf")
            document.body.appendChild(link)
            link.click()
          }
        })
      }
      else if (params.event.target.id == 'changable-btn') {
        this.showChangable = true
      }
      else if (params.event.target.id == 'tracking-id-btn' || params.event.target.parentNode.id == 'tracking-id-btn') {
        this.copyTrackingId(params.row)
      }
      else if (params.event.target.id == "pay-btn" || params.event.target.parentNode.id == 'pay-btn'){
        this.show2 = true
        this.tr_id2 = params.row.tracking_id
        this.setset = 'normal'
      }
      else{
        this.show = true
        this.infoshow = params.row
      }
    },
    // gettingwilayas(){
    //   axios.post('get-wilayas').then(response => {

    //     for (let i=0;i<response.data.data.length;i++){
    //       this.willayas.push(response.data.data[i].name)
    //     }
    //     // this.columns[4].filterOptions.filterDropdownItems= this.willayas
    //     this.$set(this.columns[4].filterOptions, 'filterDropdownItems', this.willayas);
    //   })
    filtering(params) {
      console.log(params)
    },
    getStatusList() {
      axios.post('get-status-list').then(response => {
        if (response.data.status == 'success') {
          this.items = response.data.data
        }
      })
    },
    handleModalHide(params) {
      this.show = false
      this.showChangable = false
    },
    hideModal(params) {
      this.show = false
      this.showChangable = false
    },
    serialiseDate(date) {
      if (date != null && date!= undefined)
        return moment(date).format('DD/MM/YYYY HH:mm')
      else
        return 'N/A'
    },
   detectingprovider(){
        axios.post('detectingprovider',{
          auth_token: localStorage.getItem('auth_token'),
        }).then(response => {
          this.provider = response.data.data.provider.name
          this.pemail = response.data.data.provider.email
          this.fuckoff = response.data.status
          for (let i=0;i<response.data.data.posts.length;i++){
            this.posts.push({code:response.data.data.posts[i].code,name:response.data.data.posts[i].name})
          }
        })
      },
    getingpackages(){
      axios.post('get-colis-express',{
        auth_token: localStorage.getItem('auth_token'),
      }).then(response => {
        // console.log(response.data.data.packages)
        // for (let i=0;i<response.data.data.packages.length;i++){
        //   this.packages.push({
        //     package_id:response.data.data.packages[i].tracking_id,
        //     content:response.data.data.packages[i].content,
        //     status:response.data.data.packages[i].status,
        //     wilaya:response.data.data.packages[i].wilaya,
        //     client_name:response.data.data.packages[i].client_name,
        //     price:response.data.data.packages[i].price,

        //   })

        // }
        this.rows=response.data.data.packages
        this.infoshow=response.data.data.packages
        this.delivered=response.data.data.Livrée
        this.returned=response.data.data.Returned
        this.created=response.data.data.Created
        this.engoing=response.data.data.EnRoute
        this.enhold=response.data.data.Chargement
      })
    }
  },
}
</script>
<style>

</style>